import { } from '@angular/animations/browser';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CwtCoreModule } from '@cawita/core-front';
import { CwtLoggedInGuard } from '@cawita/core-front/auth';
import { CwtZorroModule } from '@cawita/core-front/zorro';
import { CustomFieldsConfig } from '@shared/config/custom-fields.config';
import { SharedCustomFieldsModule } from '@shared/modules/custom-fields';
import { SharedUserModule } from '@shared/modules/user';
import { RecaptchaModule } from '@shared/widgets/features/common/recaptcha';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { CwtAdminCoreConfig } from './configs/core-front.config';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CwtCoreModule.forRoot(CwtAdminCoreConfig),
    CwtZorroModule.forRoot(),
    SharedUserModule.forRoot(),
    SharedCustomFieldsModule.forRoot(CustomFieldsConfig),
    RecaptchaModule.forRoot(environment.recaptchaSiteKey),
    RouterModule.forRoot([
      { path: 'auth', loadChildren: () => import('./pages/auth').then(m => m.AuthModule) },
      { path: '', canActivate: [CwtLoggedInGuard], loadChildren: () => import('./pages/main').then(m => m.MainModule) },
      { path: '**', redirectTo: '/' }
    ])
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
